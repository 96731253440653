<template>
  <div class="topnav">
    <div class="topMiddle">
      <img @click="goRouter('/')" src="@/assets/imgs/pc/topNav/topNav.png" alt="" class="logo">
      <div class="topbtn">
        <div class="product">
          <div class="topnav_btn">{{ $t("topNav.products") }}
          </div>
		  
          <div class="hover"></div>
          <div class="down" style="">
            <div class="downDrop">
              <div class="col" style="border-right:1px solid #e6ebf2">
                <div class="line" @click="goRouter('/Arcanite')">
                  <img src="../../assets/imgs/pc/topNav/p1.png" alt="">
                  <div class="text">
                    <h1>{{ $t("topNav.arcanite") }}</h1>
                    <p>{{ $t("topNav.mobile_focused") }}</p>
                  </div>
                </div>
                <div class="line" @click="goRouter('/ArcaniteConnect')">
                  <img src="../../assets/imgs/pc/topNav/p2.png" alt="">
                  <div class="text">
                    <h1>
                      {{ $t("topNav.arcanite_connect") }} 
                    </h1>
                    <p>{{ $t("topNav.manage_your_sales_operations") }}</p>
                  </div>
                </div>
                <div class="line" @click="goRouter('/ArcanitePartners')">
                  <img src="../../assets/imgs/pc/topNav/p3.png" alt="" >
                  <div class="text">
                    <h1>{{ $t("topNav.arcanite_partners") }}</h1>
                    <p>{{ $t("topNav.connect_developers_agents") }}</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <!-- <div class="line" @click="goRouter('/Equimate')">
                  <img src="../../assets/imgs/pc/topNav/p5.png" alt="">
                  <div class="text">
                    <h1>Equimate</h1>
                    <p>Help Aussies buyers get into their dream home</p>
                  </div>
                </div> -->
                <div class="line" @click="goRouter('/DragonVR')">
                  <img src="../../assets/imgs/pc/topNav/p4.png" alt="">
                  <div class="text">
                    <h1>{{ $t("topNav.dragonVR") }}</h1>
                    <p>{{ $t("topNav.bringing_off_the_plan") }}</p>
                  </div>
                </div>
                <div class="line" @click="goRouter('/Marketplace')">
                  <img src="../../assets/imgs/pc/topNav/p6.png" alt="">
                  <div class="text">
                    <h1>{{ $t("topNav.project_marketplace") }}</h1>
                    <p>{{ $t("topNav.expand_your_network_discover") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="product">
          <div class="topnav_btn" @click="goRouter('/Features')">{{ $t("topNav.features") }}
            <!-- <span class="active" :class="active==1?'activeShow':''"></span> -->
          </div>
          <div class="hover"></div>
        </div>
        <div class="product">
          <div class="topnav_btn" @click="goRouter('/Pricing')">{{ $t("topNav.pricing") }}
            <!-- <span class="active" :class="active==2?'activeShow':''"></span> -->
          </div>
          <div class="hover"></div>
        </div>
        <div class="product">
          <div class="topnav_btn" @click="goRouter('/Blogs')">{{ $t("topNav.blogs") }}
            <!-- <span class="active" :class="active==3?'activeShow':''" ></span> -->
          </div>
          <div class="hover"></div>
        </div>
        <div class="product">
          <div class="topnav_btn" @click="handleClickContactUS">{{ $t("topNav.contact_us") }}
            <!-- <span class="active" :class="active==3?'activeShow':''" ></span> -->
          </div>
          <!-- <div class="topnav_btn" >&nbsp;
          </div> -->
          <div class="hover"></div>
        </div>
        <div class="totalButtonSection">
          <div v-if="isID" class="idWhatsApp" @click="callWhatsApp">
            <img src="./../../assets/imgs/pc/homepage/Arc-log-whatsappNav.png" class="whatsAppIcon"/>
          </div>
          <div class="btnArea">
            <a v-if="!isID" onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
            return false;">
              <button class="btn" :class="[isID?'IDbtn':'']">{{ $t("pricing.book_a_demo") }}<img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="vector"></button>
            </a>
            <a v-else onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
            return false;">
              <button class="btn" :class="[isID?'IDbtn':'']">{{ $t("pricing.book_a_demo") }}<img src="./../../assets/imgs/pc/homepage/p12Vector.png" class="vector"></button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-button class="Rectangle" v-if="isRectangle" @click="handleBookBtn">
      <span> Book a demo</span>
    </el-button> -->
  </div>
</template>
<script>
export default {
  props: ['active', 'istop', 'isRectangle'],
  data() {
    return {
      isID:false,
      userId: "",
      name: "Unknown User",
      email: "",
      appid:"uu6ddns6"
    }
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.userId = 'arcweb' + new Date().getTime() + this.MathRand();
  },
  mounted() {
    // if(!this.isID && window && window.HubSpotConversations && window.HubSpotConversations.widget){
    //   window.HubSpotConversations.widget.remove(); 
    // }
          
  },
  watch: {
    // email(email) {
    //     Intercom("update",{ email:this.email })
    // },
    // name(name) {
    //     Intercom("update",{ name:this.name })
    // },
    // userId(user_id) {
    //     Intercom("update",{ user_id:this.userId })
    // },
  },
  methods: {
    callWhatsApp(){
      let data = "https://api.whatsapp.com/send?phone=6281319386791"
      window.open(data,'_system')
    },
    MathRand() {
      var Num = "";
      for (var i = 0; i < 6; i++) {
        Num += Math.floor(Math.random() * 10);
      }
      return Num
    },
    goRouter(path) {
      this.$router.push(path)
    },
    handleClickContactUS() {
      if(this.isID){
        this.callWhatsApp()
      }else if(!this.isID){
        window.HubSpotConversations.widget.open(); 
      }
    },

  },

}
</script>
<style lang="scss" scoped>
.topnav {
  width: 100%;
  height: 90px;
  position: fixed;
  // z-index: 89; // 好像有问题？
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  display: inline-block;
  text-align: center;
  // border: 1px solid #e6ebf2;
  border-bottom: 1px solid #e6ebf2;
  .topMiddle {
    width: 1210px;
    display: inline-block;
    position: relative;
    text-align: center;
    height: 90px;
    vertical-align: center;
    .logo {
      position: absolute;
      left: 0;
      width: 226px;
      // height: 58px;
      margin-right: 30px;
      margin-top: 8px;
    }
    .logo:hover{
      cursor: pointer;
    }
    .topbtn {
      position: absolute;
      right: -50px;
      height: 100%;
      display: flex;
      vertical-align: center;
      .topnav_btn {
        position: relative;
        // width: 68px;
        height: 55px;
        margin: 32px 10px 0 50px;
        // padding-bottom: 24px;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #062440;
        display: inline-block;
        cursor:pointer;
      }
      .product:hover .hover {
        display: block;
      }
      .hover{
        display: none;
        width: calc(100% - 45px);
        margin-left: 44px;
        height: 6px;
        border-top: 3px solid #1890ff;
        border-radius: 3px;
      }
      .product {
        height: 100%;
        flex-grow: 0;
        font-family: Poppins;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #062440;
        display: inline-block;
        position: relative;
      }
    }
  }
  .down {
    z-index: 99;
    width: 100vw;
    height: 284px;
    position: fixed;
    background-color: #fff;
    display: none;
    top: 90px;
    left: 0;
    border: 1px solid #e6ebf2;
  }
  .product:hover .down {
    display: block;
    // border: 1px solid #e6ebf2;
  }
  .down:hover {
    display: block;
  }
  .downDrop {
    width: 1210px;
    // border-top: 1px solid #e6ebf2;
    height: 284px;
    background-color: #fff;
    display: flex;
    margin: auto;
    .col {
      margin: 20px 10px 20px 20px;
      // width: 340px;
      padding-right: 20px;
      // height: 100%;
      .line {
        // margin: 10px 0 10px 0;
        padding: 10px;
        margin-bottom: 10px;
        // width: 328px;
        display: flex;
        img {
          width: 48px;
          height: 48px;
          border-radius: 5px;
          background-color: #f4f4f4;
        }
        .text {
          // width: 200px;
          height: 48px;
          margin: 0px 0 0 20px;
          display: inline;
          h1 {
            font-family: Poppins-SemiBold;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
          }
          p {
            font-family: Poppins;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
          }
        }
      }
      .line:hover {
        border-radius: 5px;
        background: #eef7ff;
        cursor: pointer;
      }
    }
  }
  .totalButtonSection{
    display: flex;
    flex-direction: row;
    margin-left:180px;
    // justify-content: center;
    align-items: center;
    height: 87px;
    // margin: 32px 10px 0 50px;
    // width: fit-content;
    .idWhatsApp{
      .whatsAppIcon{
        cursor: pointer;
        width: 32px;
        height:32px;
      }
    }
  }

  .btnArea {
    margin-left: 30px;
    margin-right: 20px;
    display: inline-block;
  }
  .btn {
    width: 175px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    vertical-align: centre;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    //font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
	  white-space:nowrap;
    .vector {
      width: 22px;
      height: 22px;
    }
  }
  .IDbtn{
    width: 193px;
  }
  .btn:hover{
     background-color: #096dd9;
  }
  .btn:active{
    background-color: #0757ae;
  }
}
</style>